import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Warning } from "@material-ui/icons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomDatePicker } from "components/CustomDatePicker";
import { CustomTextField } from "components/CustomTextField";
import { EmailInput } from "components/EmailInput";
import { RegionSelect } from "components/RegionSelect";
import { DocsDrawer } from "docs/DocsDrawer";
import { EngineerHandle, EngineerRole } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { DispatchActionButton } from "../components/DispatchActionButton";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import useSkills from "../skills/useSkills";
import { EngineerStaffingCalendarForm } from "./EngineerStaffingCalendarForm";
import {
  PersistEngineerAction,
  PERSIST_ENGINEER,
  SET_ENGINEER_CONFLUENCE_ACCESS,
  SET_ENGINEER_JIRA_ACCESS
} from "./EngineersTypes";
import { EngineerTimeOffForm } from "./EngineerTimeOffForm";
import { EngineerUtilizationForm } from "./EngineerUtilizationForm";
import { useAllEngineers } from "./useAllEngineers";

const schema = yup.object().shape({
  id: yup.string().optional(),
  emails: yup.array().of(yup.string()),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  harvestId: yup.string().optional(),
  role: yup.string().optional(),
  region: yup.string().optional(),
  skills: yup.array().of(yup.string()),
  offboardDate: yup.date().optional().nullable(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

export function EngineerForm() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const synching = useSelector((state: AppState) => state.engineers.synching);
  const items = useAllEngineers();
  const skills = useSkills();
  const params: { [key: string]: string } = useParams();
  const [engineer, setEngineer] = useState(new EngineerHandle({} as any));
  const [loading, setLoading] = useState(true);
  const form = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    form.register("role");
    form.setValue("role", engineer.role);
    form.register("skills");
    form.setValue("skills", engineer.skills);
    form.register("utilization");
    form.setValue("utilization", engineer.utilization);
    form.register("timeOff");
    form.setValue("timeOff", engineer.timeOff);
  }, [form, engineer]);

  function onSubmit(data: any): void {
    const offboardDate: Date | undefined = data.offboardDate;
    const action: PersistEngineerAction = {
      type: PERSIST_ENGINEER,
      item: {
        emails: (data.emails as string[] || []).map(email => email.toLowerCase()),
        firstName: data.firstName,
        harvestId: data.harvestId,
        harvestHours: engineer.harvestHours || {},
        id: data.id,
        lastName: data.lastName,
        microsoftShiftsTimeOff: engineer.microsoftShiftsTimeOff || {},
        region: data.region,
        role: data.role,
        skills: data.skills,
        staffingCalendarId: data.staffingCalendarId,
        staffingCalendarTimeOff: engineer.staffingCalendarTimeOff || {},
        timeOff: data.timeOff || [],
        utilization: data.utilization || [],
        offboardDate: offboardDate
          ? {
              year: offboardDate.getFullYear(),
              month: offboardDate.getMonth(),
              day: offboardDate.getDate(),
            }
          : undefined,
      },
    };
    const response: Promise<any> = dispatch(action) as any;
    response
      .then((msg: string) => onClose())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "",
        };
        dispatch(action);
      });
  }

  function onClose(): void {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/administration/engineers");
    }
  }

  useEffect(() => {
    const id = params.id;
    let initialItem = items[id];
    if (!id) {
      setLoading(false);
    } else if (initialItem) {
      setLoading(false);
      setEngineer(initialItem);
    }
  }, [params.id, items]);

  return (
    <Container maxWidth="md">
      {loading ? (
        <CircularProgress />
      ) : (
        <form
          className={classes.root}
          onSubmit={form.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <input
            type="hidden"
            name="id"
            value={params.id}
            ref={form.register}
          />
          <FormControl fullWidth>
            <Typography variant="h4">
              Engineer form <DocsDrawer path="/admin/engineers.html" />
            </Typography>
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="First name"
                  name="firstName"
                  defaultValue={engineer.firstName || ""}
                  error={!!form.errors.firstName}
                  inputRef={form.register}
                  helperText={form.errors.firstName?.message}
                  autoComplete="off"
                  inputProps={{
                    "data-lpignore": "true",
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Last Name"
                  name="lastName"
                  defaultValue={engineer.lastName || ""}
                  error={!!form.errors.lastName}
                  inputRef={form.register}
                  helperText={form.errors.lastName?.message}
                  autoComplete="off"
                  inputProps={{
                    "data-lpignore": "true",
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <EmailInput
                  form={form}
                  name="emails"
                  defaultValue={
                    Array.isArray(engineer.emails) ? engineer.emails : []
                  }
                />
                <FormHelperText>
                  Rackspace email is necessary to sync staffing calendar time
                  off and to grant access to engineers with certain roles.
                  <br />
                  Onica email is necessary to identify the user in harvest.
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6} style={{ display: "flex" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Tooltip
                    title="Rackspace email is required to grant access"
                    style={{
                      verticalAlign: "middle",
                      display: engineer.getRackspaceEmail() ? "none" : "inline",
                    }}
                  >
                    <Warning color="error" />
                  </Tooltip>
                  <DispatchActionButton
                    title="Grant Jira Access"
                    action={SET_ENGINEER_JIRA_ACCESS}
                    id={engineer.id}
                    disabled={!engineer.getRackspaceEmail()}
                  />
                </Box>
                <Typography
                  variant="caption"
                  style={{ color: "gray", padding: 8, textAlign: "justify" }}
                >
                  This will grant basic engineer access to Jira at{" "}
                  <strong>nbdevs.atlassian.net</strong>. Until this task is
                  fully automated please create a ticket in the MPCSUPENG board
                  to request permissions to manage sprints.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} style={{ display: "flex" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Tooltip
                    title="Rackspace email is required to grant access"
                    style={{
                      verticalAlign: "middle",
                      display: engineer.getRackspaceEmail() ? "none" : "inline",
                    }}
                  >
                    <Warning color="error" />
                  </Tooltip>
                  <DispatchActionButton
                    title="Grant Confluence Access"
                    action={SET_ENGINEER_CONFLUENCE_ACCESS}
                    id={engineer.id}
                    disabled={!engineer.getRackspaceEmail()}
                  />
                </Box>
                <Typography
                  variant="caption"
                  style={{ color: "gray", padding: 8, textAlign: "justify" }}
                >
                  This will grant access to Confluence at{" "}
                  <strong>corpinfollc.atlassian.net</strong>.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Harvest ID"
                  name="harvestId"
                  defaultValue={engineer.harvestId || ""}
                  error={!!form.errors.harvestId}
                  inputRef={form.register}
                  helperText={`If empty, this will be populated automatically based on a matching onica email in harvest. ${
                    form.errors.harvestId?.message || ""
                  }`}
                  InputProps={{
                    endAdornment: !!engineer.harvestId ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="go"
                          onClick={() =>
                            window.open(
                              `https://sturdycloud.harvestapp.com/team/${engineer.harvestId}`,
                              "_blank"
                            )
                          }
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="staffingCalendarId"
                label="Staffing calendar EMPINFOID"
                defaultValue={engineer.staffingCalendarId || ""}
                form={form}
                disabled={!!engineer.staffingCalendarId}
                helperText="if empty, this will be populated automatically based on the rackspace email"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="role"
                  defaultValue={engineer.role || ""}
                  onChange={(e) =>
                    form.setValue("role", e.target.value as string)
                  }
                  error={!!form.errors.role}
                  input={<Input />}
                  renderValue={(value) => `${value}`}
                >
                  <MenuItem key="" value="">
                    <ListItemText primary="Undefined" />
                  </MenuItem>
                  {Object.values(EngineerRole).map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  ee-lead-ca role will grant access automatically to this
                  engineer to login to the capacity planner.
                  <br />
                  {form.errors.role?.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <RegionSelect
                name="region"
                defaultValue={engineer.region}
                form={form}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={skills}
                  freeSolo
                  defaultValue={engineer.skills}
                  onChange={(_, value: string[]) =>
                    form.setValue("skills", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      error={!!form.errors.skills}
                      helperText={form.errors.skills?.message}
                      {...params}
                      variant="standard"
                      label="Skills"
                    />
                  )}
                />
              </FormControl>
              <CustomDatePicker
                control={form.control}
                record="offboardDate"
                label="Offboard date"
                year={engineer.offboardDate?.year}
                month={engineer.offboardDate?.month}
                day={engineer.offboardDate?.day}
              />
              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Utilization
                </Typography>
                <Typography variant="caption" style={{ color: "gray" }}>
                  The utilization is valid from the given date until the next
                  utilization entry or until the user is offboarded. Thus,
                  there's no need to create a new entry for each month if the
                  values remain the same. The latest entry will be used to
                  calculate the capacity for subsequent months.
                  <br />
                  Engineer hours can be distributed among{" "}
                  <strong>multiple teams</strong>.<br />
                  If entries are next to each other and they have the same date
                  then those entries are processed as concurrent utilization
                  until the next entry with a new date or until the engineer is
                  offboarded.
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EngineerUtilizationForm
                    defaultValue={engineer.utilization || []}
                    onChange={(utilization) => {
                      form.setValue("utilization", utilization);
                    }}
                  />
                </Paper>
              </FormControl>

              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Time off
                </Typography>
                {/* Commenting out temporarily
                <Typography variant="caption" style={{ color: "gray" }}>
                  Note that if the time off is already in the Microsft Shifts 
                  app it must not be added here again.
                </Typography>
                */}
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EngineerTimeOffForm
                    defaultValue={
                      Array.isArray(engineer.timeOff) ? engineer.timeOff : []
                    }
                    onChange={(timeOff) => form.setValue("timeOff", timeOff)}
                  />
                </Paper>
              </FormControl>

              {/* commenting out the Microsoft Shifts time off temporarily
              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Microsoft Shifts Time off
                </Typography>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Approved Microsoft Shifts time off is synched daily.
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EngineerStaffingCalendarForm
                    timeOff={engineer.microsoftShiftsTimeOff || {}}
                  />
                </Paper>
              </FormControl> */}
              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Staffing Calendar Time off
                </Typography>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Staffing Calendar time off is no longer used. It is displayed here for historical purposes.
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EngineerStaffingCalendarForm
                    timeOff={engineer.staffingCalendarTimeOff || {}}
                  />
                </Paper>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <FormControl margin="normal">
              <Button onClick={onClose} disabled={synching}>
                Discard
              </Button>
            </FormControl>
            <FormControl margin="normal">
              <Button
                disabled={synching}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </Grid>
        </form>
      )}
    </Container>
  );
}
